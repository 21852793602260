import React from "react";
import { projects_data } from "../utils/config/projects";
import { google_map_link, what3words_link } from "../utils/config/map-data";
import { roundToDp } from "../utils/general/numbers";

export default function ProjectW3W() {
  return (
    <div className="flex p-4">
      <div className="flex flex-col border border-black rounded-md overflow-hidden shadow-xl">
        <div>
          <div className="flex border-b border-black bg-castell text-white font-semibold py-1">
            <div className="w-20 border-r border-castell text-center">ID</div>
            <div className="w-64 border-r border-castell pl-4">Project Name</div>
            <div className="w-72 border-r border-castell pl-4">What3Words</div>
            <div className="w-64 border-r border-castell pl-4">Coordinates</div>
            <div className="w-32  text-center">Post Code</div>
          </div>
        </div>
        {Object.values(projects_data).map((project) => (
          <div className="flex border-b hover:bg-gray-100">
            <div className="w-20 border-r text-center font-semibold">{project.id}</div>
            <div className="w-64 border-r pl-4">{project.project_name || project.internal_name}</div>
            <button
              className="w-72 border-r pl-4 text-left hover:text-orangemain hover:font-semibold"
              onClick={() => window.open(what3words_link(project.address.w3w), "_blank")}
            >
              {project.address.w3w}
            </button>
            <button
              className="w-64 border-r pl-4 text-left hover:text-orangemain hover:font-semibold"
              onClick={() =>
                window.open(google_map_link(project.address.longitude, project.address.latitude, 100), "_blank")
              }
            >
              {roundToDp(parseFloat(project.address.longitude), 6)},{" "}
              {roundToDp(parseFloat(project.address.latitude), 6)}
            </button>
            <div className="w-32   text-center">{project.address.post_code}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

// import React from "react";
// import { projects_data } from "../utils/config/projects";

// // const header = ["Project Code", "Project Name", "W3W", "Coordinates", "Post Code"];
// const header = [
//   {
//     text: "ID",
//     key: "id",
//     headerClass: "w-12",
//     rowClass: "w-12",
//   },
//   {
//     text: "Project Name",
//     key: "project_name",
//     headerClass: "w-48",
//     rowClass: "w-48",
//   },
//   {
//     text: "W3W",
//     key: "address.w3w",
//     headerClass: "w-48",
//     rowClass: "w-48",
//   },
//   {
//     text: "Coordinates",
//     key: "address.longitude",
//     headerClass: "w-48",
//     rowClass: "w-48",
//   },
//   {
//     text: "Post Code",
//     key: "address.post_code",
//     headerClass: "w-48",
//     rowClass: "w-48",
//   },
// ];

// export default function ProjectW3W() {
//   return (
//     <div className="flex flex-col w-full p-4 gap-2">
//       <table className="w-full table-auto border-collapse border border-black">
//         <thead>
//           <tr>
//             {header.map((item, index) => (
//               <th key={index} className={`${item.headerClass}`}>
//                 {item.text}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {Object.values(projects_data).map((project, index) => (
//             <tr key={index}>
//               {header.map((item, index) => (
//                 <td key={index} className={`${item.rowClass}`}>
//                   {item.key.split(".").reduce((acc, key) => acc[key], project)}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
