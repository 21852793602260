const page_links = {
  home: {
    id: "home",
    text: "Home",
    link: "/",
  },
  about_us: {
    id: "about_us",
    text: "About Us",
    link: "/about-us",
  },
  vacancy: {
    id: "vacancy",
    text: "Vacancies",
    link: "/vacancies",
  },
  social_landlord: {
    id: "social_landlord",
    text: "Clients",
    link: "/clients",
  },
  project: {
    id: "project",
    text: "Projects",
    link: "/projects",
  },
  blog: {
    id: "blog",
    text: "Blogs",
    link: "/blogs",
  },
  landowner: {
    id: "landowner",
    text: "Landowners",
    link: "/landowners",
  },
  contact_us: {
    id: "contact_us",
    text: "Contact Us",
    link: "/contact-us",
  },
  // free_resource: {
  //   id: "free_resource",
  //   text: "Free Resources",
  //   link: "/free-resources",
  // },
  newsletter: {
    id: "newsletter",
    text: "Newsletter",
    link: "/newsletter",
  },
};

export { page_links };
