const roundToDp = (num, dp) => {
  return Math.round((num + Number.EPSILON) * 10 ** dp) / 10 ** dp;
};

const isNumeric = (str) => {
  return !isNaN(str);
};

const isInt = (str) => {
  return Number.isInteger(str);
};

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export { roundToDp, isNumeric, isInt, getRandomInt };
