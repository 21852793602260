import { department_data } from "./staff.js";
import { vacancies_image_urls } from "./image-links.js";

const vacancies_data = {
  mande_coordinator: {
    id: "mande_coordinator",
    title: "M&E Coordinator",
    department: department_data.technical,
    image: {
      image_url: vacancies_image_urls[2],
      image_alt: "M&E Coordinator",
    },
    active: false,
    return_email: "matthew.evans@castellgroup.co.uk",
    role_overview: [
      "As an M&E Coordinator, you will be responsible for managing all M&E activities on site. You will work closely with the site management team to coordinate the mechanical and electrical elements of our projects. Your role will involve attending site meetings, ensuring projects remain on schedule, and submitting comprehensive progress reports to the Building Services Manager.",
    ],
    responsibilities: [
      {
        header: "Manage Installations and Commissions",
        text: "Oversee the on-site installation and commissioning of all M&E activities to ensure they meet project specifications and standards.",
      },
      {
        header: "Coordination and Liaison",
        text: "Work collaboratively with designers and contractors to develop installation plans and programmes. Regularly meet with designers and subcontractors to ensure the construction, installation, and commissioning works are progressing as planned.",
      },
      {
        header: "Project Management",
        text: "Manage the mechanical, electrical, and sprinkler elements of live projects and tenders. Ensure that all works are completed within the allocated timeframes and budgets.",
      },
      {
        header: "Site Meetings and Reporting",
        text: "Attend site meetings to discuss progress and resolve any issues that may arise. Provide detailed progress reports to the Building Services Manager, highlighting any potential delays or issues.",
      },
      {
        header: "Quality Assurance",
        text: "Ensure all M&E activities comply with the relevant regulations, standards, and company policies. Conduct regular site inspections to maintain quality control.",
      },
    ],
    skills: [
      {
        header: "Experience",
        text: "Proven experience in managing mechanical and electrical activities in a construction environment. Previous experience in a similar role is essential.",
      },
      {
        header: "Technical Knowledge",
        text: "Strong understanding of mechanical, electrical, and sprinkler systems. Ability to read and interpret technical drawings and specifications.",
      },
      {
        header: "Communication Skills",
        text: "Excellent verbal and written communication skills. Ability to effectively communicate with site teams, designers, contractors, and stakeholders.",
      },
      {
        header: "Project Management",
        text: "Strong organisational and project management skills. Ability to manage multiple tasks and projects simultaneously.",
      },
      {
        header: "Problem-Solving",
        text: "Proactive approach to identifying and resolving issues. Ability to work under pressure and meet tight deadlines.",
      },
      {
        header: "Team Collaboration",
        text: "Ability to work collaboratively within a team and foster strong working relationships with all stakeholders.",
      },
    ],
    qualifications: [
      {
        text: "Bachelor’s degree in Mechanical Engineering, Electrical Engineering, Construction Management, or a related field is preferred.",
      },
      {
        text: "Relevant professional certifications or qualifications in M&E coordination or project management are a plus.",
      },
    ],
  },
  pre_construction_manager: {
    id: "pre_construction_manager",
    title: "Pre-Construction Manager",
    department: department_data.operations,
    image: { image_url: vacancies_image_urls[9], image_alt: "Pre-Construction Manager" },
    active: false,
    return_email: "matthew.evans@castellgroup.co.uk",
    role_overview: [
      "Castell Group is seeking an experienced and strategic-minded Pre-Construction Manager to join our team. As a Pre-Construction Manager, you will be responsible for overseeing all aspects of the pre-construction phase of our projects.",
    ],
    responsibilities: [
      {
        header: "Feasibility Studies and Site Assessments",
        text: "Conduct feasibility studies and site assessments to evaluate project viability.",
      },
      {
        header: "Budgeting and Timelines",
        text: "Develop preliminary project budgets and timelines based on feasibility studies.",
      },
      {
        header: "Design Development",
        text: "Collaborate with architects, engineers, and varies other consultants through RIBA stages 1-4.",
      },
      {
        header: "Project Proposals",
        text: "Prepare and review project proposals, ensuring accuracy and compliance with client requirements.",
      },
      {
        header: "Client and Stakeholder Management",
        text: "Establish and maintain relationships with key stakeholders, including clients, consultants, and regulatory bodies.",
      },
      {
        header: "Value Engineering",
        text: "Provide input on value engineering opportunities to optimise project costs without compromising quality.",
      },
      {
        header: "Pre-Construction Programmes",
        text: "Develop and implement pre-construction programmes, tracking progress and milestones.",
      },
      {
        header: "Risk Management",
        text: "Manage risk assessments and develop mitigation strategies for potential project challenges.",
      },
      {
        header: "Construction Phase Transition",
        text: "Collaborate with the construction team to transition projects from pre-construction to construction phase.",
      },
    ],
    skills: [
      {
        header: "Experience",
        text: "Proven track record of successfully delivering pre-construction services on time and within budget.",
      },
      {
        header: "Project Management",
        text: "Proficiency in project management software packages",
      },
      {
        header: "Problem-Solving",
        text: "Ability to analyse complex problems and develop innovative solutions.",
      },
      {
        header: "Communication Skills",
        text: "Strong negotiation and communication skills, with the ability to build effective relationships.",
      },
    ],
    qualifications: [
      {
        text: "Bachelor’s degree in Construction Management, Civil Engineering, Architecture, or related field.",
      },
    ],
  },
  design_technician: {
    id: "design_technician",
    title: "Design Technician",
    department: department_data.technical,
    image: { image_url: vacancies_image_urls[6], image_alt: "Design Technician" },
    active: false,
    return_email: "matthew.evans@castellgroup.co.uk",
    role_overview: [
      "Castell Group is looking for an experienced and strategic-minded Design Technician to join our team. As a Design Technician, you will be responsible for overseeing all aspects of the design phase of our projects.",
    ],
    responsibilities: [
      {
        header: "Design Management",
        text: "Ensuring drawings and designs are produced on time, to a high quality.",
      },
      {
        header: "Design Reviews",
        text: "Undertake design reviews and sign offs for various subcontract design related components; i.e., truss, plank designs, stairs, windows / doors etc.",
      },
      {
        header: "Technical Expertise",
        text: "Implement technical expertise to provide the most suitable design per project.",
      },
      {
        header: "Client Expectations",
        text: "Working with the Technical Manager to ensure the drawings align with the clients expectations, and / or clear, concise reasoning is provided as to why this isn’t achievable.",
      },
      {
        header: "Self-Checking",
        text: "Self-checking prior to Technical Manager checks to help keep the drawing approval process quick and easy.",
      },
      {
        header: "Documentation",
        text: "Maintaining thorough documentation of the design process.",
      },
      {
        header: "Design Meetings",
        text: "Attend design meetings virtual or in person to liaise with clients and / or client teams.",
      },
      {
        header: "Site Visits",
        text: "Site visits for design input and / or surveys etc.",
      },
    ],
    skills: [
      {
        header: "Technical Knowledge",
        text: "Proficiency in design software.",
      },
      {
        header: "Problem-Solving",
        text: "Ability to analyse complex problems and propose innovative design solutions.",
      },
      {
        header: "Attention to Detail",
        text: "Keen attention to detail to ensure accuracy and precision in design drawings and specifications.",
      },
      {
        header: "Project Management",
        text: "Experience in managing multiple design projects simultaneously, adhering to deadlines, and coordinating with project teams.",
      },
    ],
    qualifications: [
      {
        text: "A bachelor’s degree or equivalent certification in a relevant field such as architecture, engineering, or design.",
      },
    ],
  },
  quantity_surveyor: {
    id: "quantity_surveyor",
    title: "Quantity Surveyor",
    department: department_data.commercial,
    image: { image_url: vacancies_image_urls[5], image_alt: "Quantity Surveyor" },
    active: true,
    return_email: "",
    role_overview: [
      "A fantastic opportunity has become available for a full time Quantity Surveyor to join a high growth property development company based in South Wales that specialises in social housing. The company is expected to deliver over 300 homes per year over the next couple of years.",
      "The position is for an experienced and motivated individual that wishes to progress in their career and join a company with exciting future growth prospects. The position will be based in and around the South Wales area predominantly.",
      "This position is for working 5 days from 8:00am to 4:30pm",
      "The main duties and responsibilities will be to manage the contractual and financial side of the companies projects.",
      "The company focuses on sites between 30-50 homes in size and predominately new build residential social housing. You must be experienced in residential new build construction.",
    ],
    responsibilities: [
      {
        header: "Estimates and Appraisals",
        text: "Initial estimates and appraisals of new projects.",
      },
      {
        header: "Tendering",
        text: "Overseeing the tendering process on projects.",
      },
      {
        header: "Budgeting",
        text: "Setting up and finalising the official project budget.",
      },
      {
        header: "Cashflows",
        text: "Completing incoming and outgoing project cashflows.",
      },
      {
        header: "Commercial Management",
        text: "Ongoing commercial management – completing monthly CVRs, monthly cashflows and undertaking budget v actual variance analysis.",
      },
      {
        header: "Valuations",
        text: "Measure and value the work done on site.",
      },
      {
        header: "Payment Certs",
        text: "Manage payment certs, critically assess values and manage disputes.",
      },
      {
        header: "Liaison",
        text: "Liaise with the client and other construction professionals, such as site managers, project managers, technical and land teams.",
      },
      {
        header: "Reporting",
        text: "Complete reports as required.",
      },
      {
        header: "Team",
        text: "The commercial team comprises of highly motivated individuals and is looking for another efficient and ambitious member that want to progress and make an impact.",
      },
    ],
    skills: [
      {
        header: "Residential New Build Construction",
        text: "Experience of residential new build construction projects. (Minimum 3 years)",
      },
      {
        header: "Project Management",
        text: "Experience of managing multiple live projects simultaneously.",
      },
      {
        header: "Cashflowing",
        text: "Proficiency of project cashflows.",
      },
      {
        header: "Estimating",
        text: "Experience of estimating is appealing.",
      },
      {
        header: "Proactive",
        text: "Must be a proactive and motivated.",
      },
      {
        header: "Computer Literacy",
        text: "Strong computer literacy.",
      },
      {
        header: "Pressure",
        text: "Ability to work under pressure.",
      },
    ],
    qualifications: [
      {
        text: "Suitable qualification in Quantity Surveying",
      },
    ],
  },
  assistant_site_manager: {
    id: "assistant_site_manager",
    title: "Assistant Site Manager",
    department: department_data.operations,
    image: { image_url: vacancies_image_urls[1], image_alt: "Assistant Site Manager" },
    active: true,
    return_email: "kevin@castellgroup.co.uk",
    role_overview: [
      "As an Assistant Site Manager at Castell Group, you will be responsible for assisting the Site Manager in overseeing the construction of social housing projects in South Wales.",
      "You will play a key role in ensuring that projects are completed safely, on schedule, within budget, and to the satisfaction of our clients.",
    ],
    responsibilities: [
      {
        header: "Site Management",
        text: "Assist in planning, organising, and managing site operations.",
      },
      {
        header: "Project Management",
        text: "Ensure projects adhere to programme timelines, budget constraints, and quality standards.",
      },
      {
        header: "Site Activities",
        text: "Oversee day-to-day site activities, ensuring efficient workflow and collaboration with contractors.",
      },
      {
        header: "Communication",
        text: "Act as a liaison between Castell Group and housing associations, focusing on clear communication.",
      },
      {
        header: "Quality Control",
        text: "Monitor quality control measures, conduct inspections, and resolve issues promptly.",
      },
      {
        header: "Health and Safety",
        text: "Ensure strict adherence to health and safety standards and regulations on-site.",
      },
      {
        header: "Safety Policies",
        text: "Implement and enforce health and safety policies to create a safe working environment.",
      },
      {
        header: "Safety Inspections",
        text: "Conduct regular safety inspections and provide guidance on safe work practices.",
      },
    ],
    skills: [
      {
        header: "Project Management",
        text: "Previous project management experience in the housing sector with a focus on programming and deadlines.",
      },
      {
        header: "Communication",
        text: "Strong organisational and communication skills, familiarity with construction industry regulations.",
      },
      {
        header: "Problem-Solving",
        text: "Problem-solving skills, attention to detail, and the ability to collaborate in a team-oriented environment.",
      },
      {
        header: "Social Housing",
        text: "Experience in working on social housing projects or similar.",
      },
      {
        header: "Building Regulations",
        text: "Knowledge of building regulations and codes of practice.",
      },
      {
        header: "Communication",
        text: "Excellent communication, leadership, and problem-solving skills.",
      },
      {
        header: "Construction Management",
        text: "Relevant construction management or related certification.",
      },
    ],
    qualifications: [
      {
        text: "First Aid.",
      },
      {
        text: "SMSTS.",
      },
      {
        text: "CSCS card relevant to the role.",
      },
      {
        text: "A valid full UK driving licence.",
      },
    ],
  },
  site_manager: {
    id: "site_manager",
    title: "Site Manager",
    department: department_data.operations,
    image: { image_url: vacancies_image_urls[7], image_alt: "Site Manager" },
    active: true,
    return_email: "kevin@castellgroup.co.uk",
    role_overview: [
      "As a Site Manager at Castell Group, you will be responsible for overseeing the construction of social housing projects in South Wales.",
      "You will play a key role in ensuring that projects are completed safely, on schedule, within budget, and to the satisfaction of our clients.",
    ],
    responsibilities: [
      {
        header: "Project Management",
        text: "Manage all aspects of construction projects, from planning to completion, ensuring they meet quality, safety, and budgetary standards.",
      },
      {
        header: "Health and Safety",
        text: "Implement and enforce strict health and safety protocols on-site, conducting regular inspections and ensuring compliance with regulations.",
      },
      {
        header: "Team Management",
        text: "Lead and motivate on-site teams, to achieve project goals and maintain a positive working environment.",
      },
      {
        header: "Client Communication",
        text: "Maintain strong communication with clients, addressing their concerns and ensuring their needs are met throughout the project.",
      },
      {
        header: "Quality Control",
        text: "Oversee quality control procedures, including inspections, snagging, and compliance with building regulations and specifications.",
      },
      {
        header: "Cost Management",
        text: "Monitor project costs, track expenditures, and work to keep the project within budget.",
      },
      {
        header: "Schedule Management",
        text: "Develop and maintain project schedules, ensuring milestones are met and identifying and addressing potential delays.",
      },
      {
        header: "Documentation",
        text: "Maintain accurate project records, including daily reports, progress photos, and documentation of any changes or variations.",
      },
      {
        header: "Site Presentation",
        text: "Ensure the site is well-organized, clean, and presents a positive image to the community.",
      },
    ],
    skills: [
      {
        header: "Project Management",
        text: "Proven experience as a Site Manager in the construction industry, with a focus on social housing.",
      },
      {
        header: "Communication",
        text: "Strong knowledge of health and safety regulations and procedures.",
      },
      {
        header: "Problem-Solving",
        text: "Excellent communication and leadership skills.",
      },
      {
        header: "Social Housing",
        text: "Ability to manage and motivate a diverse team of workers and subcontractors.",
      },
      {
        header: "Building Regulations",
        text: "Proficiency in project management software and Microsoft Office.",
      },
      {
        header: "Construction Management",
        text: "Knowledge of local building regulations in South Wales.",
      },
    ],
    qualifications: [
      { text: "First Aid." },
      { text: "SMSTS." },
      { text: "CSCS card (Site Management) and SMSTS." },
      {
        text: "Preferably holds an NVQ Residential Construction Site Management Level 6 qualification.",
      },
      { text: "Valid driver’s license and reliable transportation." },
    ],
  },
  technical_manager: {
    id: "technical_manager",
    title: "Technical Manager",
    department: department_data.technical,
    image: { image_url: vacancies_image_urls[12], image_alt: "Technical Manager" },
    active: false,
    return_email: "matthew.evans@castellgroup.co.uk",
    role_overview: [
      "We are seeking a highly skilled and experienced Technical Manager to join our high growth property development company. As the Technical Manager, you will play a crucial role in overseeing and managing the technical aspects of our construction projects, with a specific focus on social housing developments. You will be responsible for ensuring the successful delivery of projects while adhering to quality standards, regulatory requirements, and timelines.",
    ],
    responsibilities: [
      {
        header: "Project Management",
        text: "Oversee the technical aspects of social housing construction projects from inception to completion, ensuring adherence to project plans, budgets, and schedules (RIBA stages 1-5).",
      },
      {
        header: "Design Coordination",
        text: "Collaborate with architects, engineers, and other stakeholders to review and coordinate design drawings, specifications, and technical documentation for social housing projects.",
      },
      {
        header: "Regulatory Compliance",
        text: "Ensure compliance with relevant building regulations, structural warranty requirements, client specification and social housing specific requirements, including design standards, accessibility, sustainability, and energy efficiency requirements.",
      },
      {
        header: "Quality Assurance",
        text: "Implement and maintain rigorous quality control and sign off procedures to ensure construction drawings are accurate and any clashes or inconsistencies have been designed out.",
      },
      {
        header: "Risk Assessment and Mitigation",
        text: "Identify potential technical risks and challenges in projects and develop effective strategies to mitigate them, ensuring smooth project execution.",
      },
      {
        header: "Budgeting, Cost Management",
        text: "Review and Monitor pre-construction project budget v actual cost.",
      },
      {
        header: "Value Engineering",
        text: "Provide technical input to ensure cost-effective solutions without compromising quality and safety.",
      },
      {
        header: "Stakeholder Management",
        text: "Liaise with colleagues, clients, contractors, suppliers, and regulatory bodies, building strong working relationships and ensuring effective communication and collaboration throughout the project lifecycle.",
      },
      {
        header: "Continuous Improvement",
        text: "Stay ahead of industry trends, best practices, and emerging technologies in social housing construction, incorporating innovative solutions to enhance project delivery and efficiency.",
      },
    ],
    skills: [
      {
        header: "Project Management",
        text: "Strong project management skills with a track record of successfully delivering construction projects on time and within budget.",
      },
      {
        header: "Communication Skills",
        text: "Exceptional communication and interpersonal skills, capable of building and maintaining relationships with diverse stakeholders.",
      },
      {
        header: "Problem-Solving",
        text: "Excellent problem-solving and decision-making abilities, with the capacity to handle multiple projects simultaneously.",
      },
      {
        header: "Technical Knowledge",
        text: "In-depth knowledge of building regulations and standards relevant to social housing construction.",
      },
      {
        header: "Experience",
        text: "Proven experience in technical management roles within the construction industry, preferably with a focus on social housing projects.",
      },
      {
        header: "Computer Literacy",
        text: "Proficiency in relevant construction software and tools.",
      },
    ],
    qualifications: [
      {
        text: "Bachelor’s degree in Civil Engineering, Construction Management, or a related field.",
      },
    ],
  },
  building_services_design_manager: {
    id: "building_services_design_manager",
    title: "Building Services Design Manager",
    department: department_data.mep,
    image: { image_url: vacancies_image_urls[14], image_alt: "Building Services Design Manager" },
    active: true,
    return_email: "james.davies@castellgroup.co.uk",
    role_overview: [
      "Castell Building Services Design Division will be delivering projects across multiple sectors from residential housing developments, healthcare, education, energy and more as we strive to achieve the right balance between form and function when faced with competing priorities around sustainability, materiality, cost and constructability. Digitalisation is at the core of our delivery model allowing our clients and their stakeholders to really experience our designs right from project concept stage and through drawing on expertise spanning decades, industries and disciplines, we assist clients to harmonise design drivers through inspired engineering, architecture and development services.",
      "This is an exciting role for an individual looking to progress their career as a Building Services Design Engineer within our industry leading team where we have an excellent reputation, offering a wealth of expertise in all sectors of the built environment.",
    ],
    responsibilities: [
      {
        text: "Prepare detailed design calculations and sketches in line with project requirements.",
      },
      {
        text: "Collaborate with Senior Engineers and Engineering Technicians on design and drawings, contributing to a dynamic team focused on delivering exceptional results.",
      },
      {
        text: "Work alongside Technical Associates on surveying, designing, specifying, and managing contracts for various projects.",
      },
      {
        text: "Undertake a combination of survey work, design tasks, office-based project administration, and site visits.",
      },
      {
        text: "Conduct detailed Building Services Design Engineering for both new construction and refurbishment projects.",
      },
      {
        text: "Support senior staff during feasibility studies and the concept and scheme design phases.",
      },
      {
        text: "Attend design team meetings, representing the department alongside colleagues.",
      },
      {
        text: "Inspect and produce reports on electrical building services systems.",
      },
      {
        text: "Use Building Services software to perform technical calculations.",
      },
      {
        text: "Ensure the design and maintenance of building systems comply with legislative, health, and safety requirements.",
      },
      {
        text: "Create marked-up drawings for CAD technicians or prepare CAD designs for issue directly.",
      },
      {
        text: "Select and specify products, equipment, and installation standards for M&E services, ensuring suitability for contractor costing and installation.",
      },
      {
        header: "Senior M&E Engineer",
        text: "Focus on design work, including surveys, designs, drawings, and specifications for various projects, working collaboratively with other members of the design team as part of an integrated project team.",
      },
      {
        header: "Principal Engineer",
        text: "Collaborate with Associates or Technical Directors on surveying, designing, specifying, and managing contracts for projects.",
      },
    ],
    skills: [
      {
        text: "Design and management of low-voltage electrical distribution systems, from incoming supply to final circuit, in compliance with BS 7671.",
      },
      {
        text: "Experience with SAPs, EPC calculations, and Overheating Schedules.",
      },
      {
        text: "Design of internal, external, and emergency lighting systems following CIBSE Lighting Guides.",
      },
      {
        text: "Design of small power systems and fixed power installations.",
      },
      {
        text: "Expertise in fire alarm and detection systems.",
      },
      {
        text: "Knowledge of ICT structured cabling systems.",
      },
      {
        text: "Proficient in designing cable containment solutions for the above systems.",
      },
      {
        text: "Skilled in the use of Amtech for electrical design and analysis.",
      },
      {
        text: "Experience in reading, interpreting, and working with drawings in AutoCAD.",
      },
      {
        text: "Solid understanding of Building Regulations Part L compliance for related systems.",
      },
      {
        text: "Proficient in the use of Microsoft Office applications.",
      },
      {
        text: "Competent in using technical software relevant to electrical design disciplines.",
      },
      {
        text: "Strong verbal and written communication skills.",
      },
    ],
    qualifications: [
      {
        text: "To be successful in this role, you will ideally hold a degree (or equivalent qualification) in Engineering and possess experience that meets the knowledge and competency standards required for an Incorporated Engineer, aligned with either IET or CIBSE criteria.",
      },
    ],
  },
  contracts_manager: {
    id: "contracts_manager",
    title: "Contracts Manager",
    department: department_data.operations,
    image: { image_url: vacancies_image_urls[15], image_alt: "Contracts Manager" },
    active: true,
    return_email: "kevin@castellgroup.co.uk",
    role_overview: [
      "Castell Group is seeking a skilled Contracts Manager to oversee and coordinate multiple social housing projects across South Wales. The successful candidate will be responsible for managing contracts from inception to completion, ensuring projects are delivered on time, within budget, and to the highest standards. This role involves close collaboration with housing associations, subcontractors, and internal teams to maintain quality and compliance while fostering positive client relationships.",
    ],
    responsibilities: [
      //       Project Management

      // Plan, manage, and oversee the execution of construction projects, ensuring full compliance with contract requirements.
      // Monitor project timelines, budgets, and resources to ensure successful delivery.
      // Manage multiple concurrent projects, balancing quality, schedules, and cost-effectiveness.
      // Implement best practices for project execution to meet or exceed client expectations.
      // Oversee project handover processes, ensuring all close-out activities are completed thoroughly.
      // Programme Development and Coordination

      // Develop and maintain comprehensive project programmes, defining clear timelines and milestones.
      // Coordinate with project teams to align resource availability and maintain programme schedules.
      // Regularly monitor and update project programmes to address delays or changes and sustain momentum.
      // Health, Safety, and Compliance

      // Ensure all site activities adhere to HSE regulations and company safety protocols.
      // Conduct regular site inspections to identify and mitigate risks.
      // Ensure compliance with CDM regulations, integrating health and safety throughout the project lifecycle.
      // Collaborate with key duty holders, including the Principal Designer and CDM Coordinator, to maintain a safe construction environment.
      // Develop and manage project-specific health and safety plans, adapting as needed to meet CDM obligations.
      // Team Leadership

      // Lead, mentor, and manage site teams, ensuring alignment with company standards and project goals.
      // Foster a collaborative, safe work environment that encourages effective communication and problem-solving.
      // Conduct monthly site manager meetings to ensure alignment and address challenges.
      // Reporting and Documentation

      // Prepare and present regular reports on project progress, budget status, and significant issues to senior management and clients.
      // Maintain transparency by keeping accurate records and detailed documentation throughout the project lifecycle.
      // Utilise reporting tools to provide real-time insights into project metrics, enabling data-driven decision-making.
      // Performance Management

      // Define and monitor Key Performance Indicators (KPIs) for project teams to ensure alignment with company objectives.
      // Conduct regular performance reviews and provide constructive feedback to team members.
      // Foster a results-driven culture that promotes accountability and focuses on achieving KPIs.
      // Stakeholder Engagement and Communication

      // Promote effective communication and collaboration across departments for seamless project delivery.
      // Engage with stakeholders, including subcontractors, suppliers, and clients, to ensure alignment and swiftly resolve issues.
      // Encourage knowledge-sharing and teamwork to improve project outcomes.
      // Financial Oversight

      // Track project expenses, monitor budgets, and authorise invoices to ensure financial control.
      // Prepare financial performance reports, including timelines and any variations, to maintain transparency with clients and management.
      // Pre-Construction Activities

      // Collaborate with stakeholders and design teams during the pre-construction phase to align on project requirements and objectives.
      // Assist in identifying potential risks and constraints that could impact delivery.
      // Contribute to detailed project planning and resource assessments to evaluate project feasibility.
      {
        header: "Project Management",
        blocks: [
          "Plan, manage, and oversee the execution of construction projects, ensuring full compliance with contract requirements.",
          "Monitor project timelines, budgets, and resources to ensure successful delivery.",
          "Manage multiple concurrent projects, balancing quality, schedules, and cost-effectiveness.",
          "Implement best practices for project execution to meet or exceed client expectations.",
          "Oversee project handover processes, ensuring all close-out activities are completed thoroughly.",
        ],
      },
      {
        header: "Programme Development and Coordination",
        blocks: [
          "Develop and maintain comprehensive project programmes, defining clear timelines and milestones.",
          "Coordinate with project teams to align resource availability and maintain programme schedules.",
          "Regularly monitor and update project programmes to address delays or changes and sustain momentum.",
        ],
      },
      {
        header: "Health, Safety, and Compliance",
        blocks: [
          "Ensure all site activities adhere to HSE regulations and company safety protocols.",
          "Conduct regular site inspections to identify and mitigate risks.",
          "Ensure compliance with CDM regulations, integrating health and safety throughout the project lifecycle.",
          "Collaborate with key duty holders, including the Principal Designer and CDM Coordinator, to maintain a safe construction environment.",
          "Develop and manage project-specific health and safety plans, adapting as needed to meet CDM obligations.",
        ],
      },
      {
        header: "Team Leadership",
        blocks: [
          "Lead, mentor, and manage site teams, ensuring alignment with company standards and project goals.",
          "Foster a collaborative, safe work environment that encourages effective communication and problem-solving.",
          "Conduct monthly site manager meetings to ensure alignment and address challenges.",
        ],
      },
      {
        header: "Reporting and Documentation",
        blocks: [
          "Prepare and present regular reports on project progress, budget status, and significant issues to senior management and clients.",
          "Maintain transparency by keeping accurate records and detailed documentation throughout the project lifecycle.",
          "Utilise reporting tools to provide real-time insights into project metrics, enabling data-driven decision-making.",
        ],
      },
      {
        header: "Performance Management",
        blocks: [
          "Define and monitor Key Performance Indicators (KPIs) for project teams to ensure alignment with company objectives.",
          "Conduct regular performance reviews and provide constructive feedback to team members.",
          "Foster a results-driven culture that promotes accountability and focuses on achieving KPIs.",
        ],
      },
      {
        header: "Stakeholder Engagement and Communication",
        blocks: [
          "Promote effective communication and collaboration across departments for seamless project delivery.",
          "Engage with stakeholders, including subcontractors, suppliers, and clients, to ensure alignment and swiftly resolve issues.",
          "Encourage knowledge-sharing and teamwork to improve project outcomes.",
        ],
      },
      {
        header: "Financial Oversight",
        blocks: [
          "Track project expenses, monitor budgets, and authorise invoices to ensure financial control.",
          "Prepare financial performance reports, including timelines and any variations, to maintain transparency with clients and management.",
        ],
      },
      {
        header: "Pre-Construction Activities",
        blocks: [
          "Collaborate with stakeholders and design teams during the pre-construction phase to align on project requirements and objectives.",
          "Assist in identifying potential risks and constraints that could impact delivery.",
          "Contribute to detailed project planning and resource assessments to evaluate project feasibility.",
        ],
      },
    ],
    skills: [
      {
        text: "Strong understanding of contract law, project management methodologies, and construction best practices.",
      },
      {
        text: "Proficiency in project management software, the Microsoft Office Suite, and familiarity with construction technology and methods.",
      },
      {
        text: "Demonstrated leadership abilities and strong interpersonal skills.",
      },
      {
        text: "Excellent verbal and written communication skills, including the ability to negotiate and resolve issues diplomatically.",
      },
      {
        text: "Proven ability to manage multiple projects and effectively prioritise tasks.",
      },
      {
        text: "Detail-oriented with a commitment to accuracy and maintaining high-quality standards.",
      },
      {
        text: "Strong problem-solving and analytical skills to address project challenges effectively.",
      },
    ],
    qualifications: [
      {
        header: "Experience",
        text: "At least 5+ years in contract management, with a strong focus on the social housing sector.",
      },
      {
        header: "Education",
        text: "Level 7 NVQ in Construction Management, Civil Engineering, or a related field.",
      },
      {
        header: "Certifications",
        text: "Professional qualifications such as MCIOB (Member of the Chartered Institute of Building) or MRICS (Member of the Royal Institution of Chartered Surveyors) are advantageous.",
      },
    ],
  },
};

export { vacancies_data };
