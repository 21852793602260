import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations";
import { UserContext } from "../context_providers/UserContext";

const pages = [
  {
    id: "overview",
    text: "Project Overview",
  },
  {
    id: "photos",
    text: "Photos",
  },
  {
    id: "milestones",
    text: "Key Milestones",
  },
  {
    id: "faq",
    text: "FAQ",
  },
];

export default function ProjectInformationLayout() {
  const { projectInformationPage, setProjectInformationPage } = useContext(UserContext);

  return (
    <div className="flex flex-col w-screen h-screen bg-white min-h-228">
      <div className="flex font-semibold w-full items-center px-[5%] py-2 border-b border-black shadow-lg gap-4">
        {pages.map((page) => (
          <button
            key={page.id}
            className={`"flex px-8 py-1 text-center rounded-sm border ${
              projectInformationPage.id === page.id
                ? "bg-castell text-white border-castell"
                : `${handleScaleInlineTailwind300} hover:border-orangemain border-gray-400 hover:text-orangemain`
            }`}
            onMouseEnter={(e) => {
              projectInformationPage.id !== page.id && e.currentTarget.classList.add("scale-105");
            }}
            onMouseLeave={(e) => {
              projectInformationPage.id !== page.id && e.currentTarget.classList.remove("scale-105");
            }}
            onClick={() => setProjectInformationPage(page)}
          >
            {page.text}
          </button>
        ))}
      </div>

      <div className="flex flex-grow p-4">
        <Outlet />
      </div>
    </div>
  );
}
