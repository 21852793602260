import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../context_providers/UserContext";
import { getTextAfterLastSlash } from "../utils/general/text-formatting";

export default function ProjectInformation() {
  const { id } = useParams();
  const { location, projectInformationPage } = useContext(UserContext);
  const project = getTextAfterLastSlash(location.pathname);
  // const project = projects_data[projectId];

  return (
    <div className="flex flex-grow border border-black rounded-lg shadow-2xl p-4">
      {projectInformationPage.id === "overview" && (
        <div>
          <p>Overview</p>
        </div>
      )}
      {projectInformationPage.id === "photos" && (
        <div>
          <p>Photos</p>
        </div>
      )}
      {projectInformationPage.id === "milestones" && (
        <div>
          <p>Milestones</p>
        </div>
      )}
      {projectInformationPage.id === "faq" && (
        <div>
          <p>FAQ</p>
        </div>
      )}
    </div>
  );
}
