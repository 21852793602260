import { useParams } from "react-router-dom";
import { UserContext } from "../context_providers/UserContext.jsx";
import { useContext } from "react";
import { projects_data, project_descriptions } from "../utils/config/projects.js";
import { getTextAfterLastSlash, insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { castellRegex } from "../utils/general/regex.js";
import { template_image_dark, template_image_light } from "../utils/config/image-links.js";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  map_config,
  icon_styles,
  icon_template,
  project_map_zoom,
  popup_template,
  google_map_link,
  // what3words_link,
  AutoOpenMarker,
} from "../utils/config/map-data.js";
import { res_map_popup_image, res_our_portfolio_projects } from "../utils/config/image-resolutions.js";
import ImageCarousel from "../components/ImageCarousel.jsx";
import { getRandomElements } from "../utils/general/misc.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { page_links } from "../utils/config/page-links.js";
import { ButtonWithLoadingBackground } from "../components/ImageLoaders.jsx";

export default function Project() {
  const { id } = useParams();
  const { location } = useContext(UserContext);
  const projectId = getTextAfterLastSlash(location.pathname);
  const project = projects_data[projectId];

  if (!castellRegex.projectCode.test(id) || !project) {
    window.location.href = "/not-found";
  }

  return (
    <div className="flex flex-col w-full bg-white">
      <ImageCarousel
        urls={Array.isArray(project.cgi_urls) && project.cgi_urls.length > 0 ? project.cgi_urls : [template_image_dark]}
        text={[project.given_name || project.project_name]}
        className="relative w-full h-40 lg:h-64 xl:h-96 overflow-hidden flex items-center justify-center bg-white"
      />

      <div className="flex flex-col w-full py-16 px-[10%] gap-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col w-full lg:w-[50%] gap-8">
            {/* <div className="flex border border-black shadow-xl rounded-md py-4 text-xl font-semibold justify-center">
              <button
                aria-label="View project on what 3 words"
                className={`hover:text-castell ${handleScaleInlineTailwind300}`}
                onClick={() => window.open(what3words_link(project.address.w3w), "_blank")}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              >
                what3words: {project.address.w3w}
              </button>
            </div> */}
            {project.address.longitude && project.address.latitude && (
              <div
                className={`h-64 ${
                  project.main_video_url && project.main_video_url !== "" ? "lg:h-192" : "lg:h-144"
                } shadow-lg w-full`}
              >
                <MapContainer
                  center={[project.address.longitude, project.address.latitude]}
                  zoom={project_map_zoom}
                  maxBounds={map_config.maxBounds}
                  maxBoundsViscosity={map_config.maxBoundsViscosity}
                  minZoom={map_config.minZoom}
                  className="h-full w-full border border-black rounded-lg overflow-hidden z-10"
                >
                  <TileLayer url={map_config.tileLayerUrl} attribution={map_config.tileLayerAttribution} />
                  <Marker
                    key={project.id}
                    position={[project.address.longitude, project.address.latitude]}
                    icon={icon_template(icon_styles[project.stage_id])}
                  >
                    <AutoOpenMarker
                      key={project.id}
                      data_object={project}
                      icon={icon_template(icon_styles["castell"])}
                      popupContent={popup_template(
                        project,
                        template_image_dark,
                        res_map_popup_image,
                        "cgi",
                        google_map_link(project.address.longitude, project.address.latitude, 100)
                      )}
                    />
                  </Marker>
                </MapContainer>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-8 w-full lg:w-[50%]">
            {project_descriptions[project.id] && (
              <div className="flex w-full items-center justify-center p-4 rounded-md border border-castellpastel-200 shadow-lg">
                <div>{project_descriptions[project.id]()}</div>
              </div>
            )}

            {project.main_video_url && project.main_video_url !== "" ? (
              <div className="flex w-full h-96 lg:h-full shadow-lg">
                <iframe
                  src={project.main_video_url}
                  title={project.project_name + " video"}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="flex w-full h-full rounded-lg"
                />
              </div>
            ) : (
              <div className="flex w-full h-96 lg:h-full rounded-md border border-castellpastel-200 justify-center items-center shadow-lg text-3xl font-semibold">
                Video Pending
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row w-full text-white font-semibold text-lg gap-4">
          {getRandomElements(
            Object.values(projects_data).filter((project) => project.id !== projectId),
            5
          ).map((project, index) => (
            <ButtonWithLoadingBackground
              aria_label={`View ${project.given_name || project.project_name}`}
              key={index}
              onClick={() => (window.location.href = `${page_links["project"].link}/${project.id}`)}
              className={`relative w-full h-16 md:h-32 bg-cover bg-center rounded-md overflow-hidden transition-transform duration-150 shadow-lg ${handleScaleInlineTailwind300}`}
              imageUrl={insertTextbeforeUrlExtention(
                project.main_cgi_url !== "" ? project.main_cgi_url : template_image_light,
                res_our_portfolio_projects
              )}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-105")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-105")}
            >
              <div className="absolute inset-0 bg-castell bg-opacity-35 backdrop-blur-extra-sm"></div>
              <span className="relative">{project.given_name || project.project_name}</span>
            </ButtonWithLoadingBackground>
          ))}
        </div>
      </div>
    </div>
  );
}
